import React from "react"

export const AddToHomeScreenButton = () => {
  return (
    <div id="square" className="addToHomeScreenSquare">
      <span>
        ¿Quieres Sendity en tu teléfono? Haz click aquí para guardar un acceso
        directo a Sendity en tu teléfono.
      </span>
      <div className="button-box">
        <button
          type="button"
          className="cancel-button"
          id="cancelar_acceso_directo"
        >
          cancelar
        </button>
        <button type="button" className="add-button" id="acceso_directo">
          aceptar
        </button>
      </div>
    </div>
  )
}
