import React, { Fragment, useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { LangSwitch } from "../LangSwitch";
import GatsbyImages from "../../GatsbyImages";
import logowhite from "../../../images/Logo_sendity/logowhite.svg";
import logocolor from "../../../images/Logo_sendity/logocolor.svg";
import "./header.css";

export const Header = ({ lang, location, bannerDisplay, langSwitchURL }) => {
  const isEng = lang === "en";
  const href = location.href?.toLowerCase();
  const pathname = location.pathname?.toLowerCase();
  const data = useStaticQuery(graphql`
    query StaticNavQuery {
      allStrapiNav {
        nodes {
          locale
          menu {
            name
            path
            target
            classes
          }
          menuServices {
            name
            path
            target
            classes
            tipo
          }
          bannertext
        }
      }
    }
  `).allStrapiNav.nodes.filter(({ locale }) => locale === lang)[0];

  const isOpen = false;
  //const [isOpen, setIsOpen] = useState(false);
  //const [isCloseBanner, setIsCloseBanner] = useState(false)
  const [isCookie, setIsCookie] = useState(true);

  useEffect(() => {
    var c_value = document.cookie;
    var c_start = c_value.indexOf(" bannertop=");
    if (c_start === -1) {
      c_start = c_value.indexOf("bannertop=");
    }
    if (c_start === -1) {
      c_value = false;
    } else {
      c_start = c_value.indexOf("=", c_start) + 1;
      var c_end = c_value.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = c_value.length;
      }
      c_value = unescape(c_value.substring(c_start, c_end));
    }

    setIsCookie(c_value);
  }, [isCookie]);

  useEffect(() => {
    if (!document) return;
    //remove open-menu between navigations
    const element = document.getElementsByTagName("body")[0];
    if (Array.from(element.classList).includes("open-menu")) {
      element.classList.remove("open-menu");
    }
  }, [pathname]);

  const setCookie = (c_name, value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
    setIsCookie(true);
  };

  const close = (e) => {
    e.preventDefault();
    setCookie(`bannertop`, true, 365);
    // setIsCloseBanner(true)
    // window.location.reload()
  };

  const menuIconToggle = (e) => {
    e.preventDefault();
    if (!document) return;
    const element = document.getElementsByTagName("body")[0];
    const classes = element.classList;
    Array.from(classes).includes("open-menu")
      ? element.classList.remove("open-menu")
      : element.classList.add("open-menu");
  };

  const menuservices = data.menuServices.filter(
    (menu) => menu.tipo === "services",
  );

  const locationObj = {
    home:
      !href?.includes("recargas") &&
      !href?.includes("enviar-dinero") &&
      !href?.includes("recharge") &&
      !href?.includes("send-money"),
    recarga: href?.includes("recargas") || href?.includes("recharge"),
    remesas: href?.includes("enviar-dinero") || href?.includes("send-money"),
  };

  const filterMenu = data.menuServices.reduce((result, obj) => {
    const tipo = obj.tipo;

    // Create a new array for the tipo if it doesn't exist
    result[tipo] = result[tipo] || [];

    // Push the current object to the array of the corresponding tipo
    result[tipo].push(obj);

    return result;
  }, {});

  // Find the key in locationObj with a value of true
  const currentLocationKey = Object.keys(locationObj).find(
    (key) => locationObj[key],
  );

  // Access the corresponding array in filterMenu
  let menugeneral = filterMenu[currentLocationKey];

  menugeneral.sort((a, b) => {
    if (a.name.toLowerCase() === "sobre nosotros") return -1;
    if (b.name.toLowerCase() === "sobre nosotros") return 1;

    return 0;
  });

  let isSendMoneyRenderConditionsMeet = true;
  let ishome = false;
  if (pathname === "/" || pathname === "/en/") {
    ishome = true;
    isSendMoneyRenderConditionsMeet = false;
  }

  let nim = 0;

  const redirectHomePath = location.pathname.split("/").includes("en")
    ? "/en/"
    : "/";

  const socialMediaLinks = [
    {
      name: "Facebook",
      url: "https://facebook.com/sendityapp/",
      iconClass: "fa arm fa-facebook",
    },
    {
      name: "YouTube",
      url: "https://www.youtube.com/channel/UCHmC0BHD5VJwlVV-S3Y0WLQ",
      iconClass: "fa arm fa-youtube-play",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/sendity/",
      iconClass: "fa arm fa-linkedin",
    },
    {
      name: "Instagram",
      url: "https://instagram.com/sendityapp",
      iconClass: "fa arm fa-instagram",
    },
  ];

  const bannerLocationIsAllowed = bannerDisplay;
  const bannerCookieIsNotSet = !isCookie;
  const bannerTextIsFetched = !!data?.bannertext;

  const bannerConditions =
    bannerLocationIsAllowed && bannerCookieIsNotSet && bannerTextIsFetched;

  const reviewdBannerText = bannerTextIsFetched
    ? data.bannertext.replace(/strong/g, "b")
    : null;

  return (
    <>
      {bannerConditions && (
        <div className="bannertop" id="bannerTop" style={{ zIndex: 1 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: reviewdBannerText,
            }}
          ></div>
          <button
            className="bannertop_close-button"
            onClick={close}
            tabIndex={0}
            aria-label="Close"
          ></button>
        </div>
      )}

      <header
        className={
          ishome === true
            ? "sticky_header d-flex flex-wrap pt-0 stick_header_home"
            : "sticky_header d-flex flex-wrap pt-0"
        }
        id="header"
      >
        <div className="container row">
          <div className="header-lft col-2">
            <div className="logo">
              <Link to={redirectHomePath}>
                <GatsbyImages
                  alt="logo sendity"
                  src={logowhite}
                  width={120}
                  height={30}
                />
              </Link>
            </div>
            <div className="sticky_logo">
              <Link to={redirectHomePath}>
                <GatsbyImages
                  alt="logo sendity"
                  src={logocolor}
                  width={120}
                  height={30}
                />
              </Link>
            </div>
            <button className="menu-icon" onClick={menuIconToggle}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="linksheader">
            <ul>
              {menugeneral.map(({ name, classes, path, target }, index) => {
                if (
                  isEng
                    ? name.toLowerCase() !== "about us" &&
                      name.toLowerCase() !== "help"
                    : name.toLowerCase() !== "sobre nosotros" &&
                      name.toLowerCase() !== "ayuda"
                ) {
                  return (
                    <li className={classes} key={index}>
                      <a href={path} target={target}>
                        {name}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
          <div
            className={
              isOpen !== true
                ? "header-rgt col-10 openmenu"
                : "header-rgt col-10"
            }
          >
            <nav>
              <ul
                className="d-flex flex-wrap clearfix pl-0"
                style={{ paddingLeft: 0 }}
              >
                {isSendMoneyRenderConditionsMeet ? (
                  <div className="menuservices">
                    {menuservices.map(
                      ({ name, classes, path, target }, index) => {
                        return (
                          <li className={classes} key={index}>
                            <Link to={path} target={target}>
                              {name}
                            </Link>
                          </li>
                        );
                      },
                    )}
                  </div>
                ) : null}

                {menugeneral.map(({ name, classes, path, target }, index) => {
                  if (
                    isEng
                      ? name.toLowerCase() === "help" ||
                        name.toLowerCase() === "about us"
                      : name.toLowerCase() === "ayuda" ||
                        name.toLowerCase() === "sobre nosotros"
                  ) {
                    nim = nim + 1;
                    return (
                      <li className={classes} key={index}>
                        <Link to={path} target={target}>
                          {name}
                        </Link>
                      </li>
                    );
                  }
                  if (
                    isEng
                      ? name.toLowerCase() !== "about us" &&
                        name.toLowerCase() !== "help" &&
                        nim === 2
                      : name.toLowerCase() !== "sobre nosotros" &&
                        name.toLowerCase() !== "ayuda" &&
                        nim === 2
                  ) {
                    nim = nim + 1;
                    return (
                      <Fragment key={index}>
                        <LangSwitch
                          isHome={ishome}
                          name={name}
                          path={path}
                          target={target}
                          classes={classes}
                          location={location}
                          langSwitchURL={langSwitchURL}
                        />
                      </Fragment>
                    );
                  }
                  // these are external links

                  return (
                    <li className={classes} key={index}>
                      <a
                        href={path}
                        target={target}
                        style={{
                          backgroundColor: ishome ? "#123A4C" : "#24769D",
                        }}
                      >
                        {name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className="social-media">
              <ul className="d-flex flex-wrap clearfix">
                {socialMediaLinks.map((link, index) => (
                  <li key={index} className="col-3">
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={link.iconClass} aria-hidden="true"></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
