exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-about-us-page-about-us-jsx": () => import("./../../../src/components/AboutUsPage/AboutUs.jsx" /* webpackChunkName: "component---src-components-about-us-page-about-us-jsx" */),
  "component---src-components-blog-page-blog-page-jsx": () => import("./../../../src/components/BlogPage/BlogPage.jsx" /* webpackChunkName: "component---src-components-blog-page-blog-page-jsx" */),
  "component---src-components-blog-page-categories-categories-jsx": () => import("./../../../src/components/BlogPage/Categories/Categories.jsx" /* webpackChunkName: "component---src-components-blog-page-categories-categories-jsx" */),
  "component---src-components-blog-page-post-page-jsx": () => import("./../../../src/components/BlogPage/PostPage.jsx" /* webpackChunkName: "component---src-components-blog-page-post-page-jsx" */),
  "component---src-components-calculadora-calculadora-jsx": () => import("./../../../src/components/Calculadora/Calculadora.jsx" /* webpackChunkName: "component---src-components-calculadora-calculadora-jsx" */),
  "component---src-components-calculadora-recargas-calculadora-recargas-jsx": () => import("./../../../src/components/Calculadora_Recargas/Calculadora_Recargas.jsx" /* webpackChunkName: "component---src-components-calculadora-recargas-calculadora-recargas-jsx" */),
  "component---src-components-contact-page-contact-jsx": () => import("./../../../src/components/ContactPage/Contact.jsx" /* webpackChunkName: "component---src-components-contact-page-contact-jsx" */),
  "component---src-components-home-home-jsx": () => import("./../../../src/components/Home/Home.jsx" /* webpackChunkName: "component---src-components-home-home-jsx" */),
  "component---src-components-page-component-page-jsx": () => import("./../../../src/components/PageComponent/Page.jsx" /* webpackChunkName: "component---src-components-page-component-page-jsx" */),
  "component---src-components-recharges-page-recharges-page-jsx": () => import("./../../../src/components/RechargesPage/RechargesPage.jsx" /* webpackChunkName: "component---src-components-recharges-page-recharges-page-jsx" */),
  "component---src-components-send-money-countries-send-money-countries-jsx": () => import("./../../../src/components/SendMoneyCountries/SendMoneyCountries.jsx" /* webpackChunkName: "component---src-components-send-money-countries-send-money-countries-jsx" */),
  "component---src-components-send-money-page-send-money-page-jsx": () => import("./../../../src/components/SendMoneyPage/SendMoneyPage.jsx" /* webpackChunkName: "component---src-components-send-money-page-send-money-page-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

