import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const index = ({
  src,
  placeholder = "blurred",
  ext = ".svg",
  alt,
  ...props
}) => {
  if (ext?.includes("svg")) {
    return (
      <img src={src} loading="lazy" decoding="async" alt={alt} {...props} />
    );
  }

  const image = getImage(src);

  return (
    <GatsbyImage image={image} alt={alt} {...props} placeholder={placeholder} />
  );
};

export default index;
