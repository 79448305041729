import React, { Fragment, useEffect, useState } from "react";
import { Link } from "gatsby";
import "./langSwitch.css";

const LangSwitch = ({
  isHome,
  name,
  path,
  target,
  classes,
  orientation = "down",
  location,
  langSwitchURL,
}) => {
  const selectedLang = location.pathname.split("/").includes("en")
    ? "english"
    : "español";

  const langArr = [
    {
      lng: "es",

      language: "Español",
    },
    {
      lng: "en",

      language: "English",
    },
  ];

  if (location.pathname.includes("/categories/")) {
    langArr.forEach(({ lng }, index) => {
      if (lng === "es") {
        langArr[index].path = langSwitchURL.es;
      }
      if (lng === "en") {
        langArr[index].path = langSwitchURL.en;
      }
    });
  }
  if (!location.pathname.includes("/categories/")) {
    langArr.forEach(({ lng }, index) => {
      if (lng === "es") {
        langArr[index].path = langSwitchURL?.split("/")?.includes("en")
          ? location.pathname
          : langSwitchURL;
      }
      if (lng === "en") {
        langArr[index].path = !langSwitchURL?.split("/")?.includes("en")
          ? location.pathname
          : langSwitchURL;
      }
    });
  }

  return (
    <Fragment>
      <li className="langmenu_main">
        <span
          className="langmenu_selected-lang"
          style={{
            "--custom-color": isHome ? "#000" : "#fff",
            fontWeight: "300",
          }}
        >
          {selectedLang}
          <span className="sr-only">{selectedLang}</span>
        </span>

        <ul
          className="langmenu_menu-list"
          style={{
            marginTop: orientation === "up" ? "-110px" : "8px",
          }}
        >
          {langArr.map(({ lng, path, language }) => (
            <li key={lng}>
              <Link to={path}>{language}</Link>
            </li>
          ))}
        </ul>
      </li>
      {name && path ? (
        <li className={classes}>
          <a href={path} target={target}>
            {name}
          </a>
        </li>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export { LangSwitch };
